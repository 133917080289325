import React from 'react'
import { ActionButtonProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import Button from '@sport1/news-styleguide/Button'
import nofollowExternal from '@/utils/url/nofollowExternal'

type MediathekActionButtonProps = {
    backgroundColor?: 'TRANSPARENT' | 'pearl' | 'onyx'
    onPressCallback?: () => void
    actionButton: ActionButtonProps
    buttonVariant?: 'primary' | 'secondary' | 'tertiary' | 'highlight'
    teaserHref?: string
}

const MediathekActionButton = ({
    actionButton,
    buttonVariant = 'tertiary',
    backgroundColor,
    onPressCallback,
    teaserHref,
}: MediathekActionButtonProps) => {
    if (!onPressCallback && !teaserHref) return null
    return (
        <NonFlexingContainer
            key="action-button-wrapper"
            marginTop={['spacing-6', 'spacing-9', 'spacing-9']}
            horizontal
            centerHorizontal
            backgroundColor={backgroundColor}
            centerVertical
            testID="action-button-wrapper"
        >
            <FlexingContainer
                centerVertical
                maxWidth={['369px', '290px', '369px', '369px', '762px']}
            >
                <Button
                    expand
                    testID="action-button"
                    buttonType={['M', 'M', 'L']}
                    textAlign="center"
                    buttonVariant={buttonVariant}
                    text={actionButton.title}
                    href={teaserHref}
                    webRel={nofollowExternal}
                    onPress={onPressCallback}
                />
            </FlexingContainer>
        </NonFlexingContainer>
    )
}

export default MediathekActionButton
