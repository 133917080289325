import React from 'react'
import { AdProps, ComponentType, MediathekTeaserProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { ItemCardProps } from '../types'
import SingleMediathekTeaser from '../SingleMediathekTeaser'
import HorizontalSlider from '@/components/HorizontalSlider'

const MediathekHorizontalCardContent = ({
    teaser,
    backgroundColor,
    horizontalScrollbarEnabled,
    arrowMargin,
    spaceX,
}: ItemCardProps) => {
    return (
        <NonFlexingContainer testID="horizontal-slider-wrapper">
            <HorizontalSlider<MediathekTeaserProps | AdProps>
                items={teaser}
                testID="mediathek-horizontal-card-slider"
                keyExtractor={(item, index) =>
                    item.type !== ComponentType.AD
                        ? `${item.title}_${item.publishedAt}_${index}`
                        : `${item.adIndex}_${index}`
                }
                renderItem={({ index, item }) => (
                    <SingleMediathekTeaser
                        index={index}
                        key={`${item.title}-${item.id}-${item.componentKey}`}
                        isHorizontal={true}
                        isCardTeaser={true}
                        verticalExpand={true}
                        noExpand={true}
                        teaser={item}
                        backgroundColor={backgroundColor}
                    />
                )}
                backgroundColor={backgroundColor}
                spaceX={spaceX || 'none'}
                horizontalScrollbarEnabled={horizontalScrollbarEnabled}
                arrowMargin={arrowMargin}
                spaceBetween={['standard', 'medium', 'medium']}
                buttonVariant="primary"
                arrowButtonSize={['M', 'L', 'XL']}
            />
        </NonFlexingContainer>
    )
}

export default MediathekHorizontalCardContent
