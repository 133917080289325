import React, { FC, memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import MediathekListTeaser from './MediathekListTeaser'
import { MediathekTeaserCardProps } from './types'
import MediathekActionButton from '@/components/MediathekTeaserCard/MediathekActionButton'
import MediathekCard from '@/components/MediathekCard'
import MediathekHorizontalCardContent from '@/components/MediathekTeaserCard/MediathekHorizontalList'
import { isCorrectSize } from '@/context/AdPlacementContext/AdPlacementProvider.utils'
import { createPlaylistHref } from '@/utils/navigation/Navigator'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'

const MediathekTeaserCard: FC<MediathekTeaserCardProps> = ({
    backgroundColor,
    teaser: unfilteredTeasers,
    title,
    horizontal,
    actionButtons,
    tag,
    horizontalScrollbarEnabled,
    arrowMargin,
    spaceX,
    hintHref,
    forceSingleRow,
    isTopTeaserKategorien,
    isOnKategorienPage,
}) => {
    const { currentDevice } = useMediaQueryContext()
    const {
        asPath,
        query: { slug },
    } = useRouter()

    const isTagSlugSameAsCurrentPathName =
        `/${(slug as string[])?.join('/')}` === tag?.slug ||
        `/tv-video/${(slug as string[])?.join('/')}` === tag?.slug

    const teaserHref =
        useMemo(() => {
            if (
                (asPath.startsWith('/tv-video') || tag?.url?.includes('/layoutMode/VIDEO')) &&
                !isTagSlugSameAsCurrentPathName
            ) {
                if (tag?.slug === '/tv-video/kategorien') {
                    return tag?.slug
                }
                return createPlaylistHref(tag)
            }
        }, [asPath, tag, isTagSlugSameAsCurrentPathName]) ?? hintHref

    const teaser = useMemo(
        () => unfilteredTeasers.filter(item => isCorrectSize(item, currentDevice === 'mobile')),
        [currentDevice, unfilteredTeasers]
    )

    if (teaser?.length === 0) return null
    return (
        <NonFlexingContainer
            margin="auto"
            overflow="hidden"
            backgroundColor="pearl"
            maxWidth={[
                isOnKategorienPage ? '294px' : '369px',
                '604px',
                '762px',
                '1155px',
                '1548px',
            ]}
            width="100%"
            testID="mediathek-teaser-card"
        >
            <MediathekCard
                backgroundColor={backgroundColor}
                title={title}
                titleHref={teaserHref}
                isTopTeaserKategorien={isTopTeaserKategorien}
            >
                <FlexingContainer testID="mediathek-card-content">
                    {horizontal ? (
                        <MediathekHorizontalCardContent
                            teaser={teaser}
                            backgroundColor="TRANSPARENT"
                            horizontalScrollbarEnabled={horizontalScrollbarEnabled}
                            arrowMargin={arrowMargin}
                            spaceX={spaceX}
                        />
                    ) : (
                        <MediathekListTeaser
                            teaser={teaser}
                            forceSingleRow={forceSingleRow}
                            isTopTeaserKategorien={isTopTeaserKategorien}
                            backgroundColor={backgroundColor}
                            title={title}
                        />
                    )}
                    {actionButtons && (
                        <MediathekActionButton
                            actionButton={actionButtons[0]}
                            buttonVariant="primary"
                            backgroundColor={backgroundColor}
                            teaserHref={teaserHref}
                        />
                    )}
                </FlexingContainer>
            </MediathekCard>
        </NonFlexingContainer>
    )
}

export default memo(MediathekTeaserCard)
