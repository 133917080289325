import React, { FC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { ItemCardProps } from '../types'
import SingleMediathekTeaser from '../SingleMediathekTeaser'

type MediathekTeaserListProps = ItemCardProps & {
    isCardTeaser?: boolean
    numberOfItems?: number
    forceSingleRow?: boolean
    isTopTeaserKategorien?: boolean
}

const MediathekListTeaser: FC<MediathekTeaserListProps> = ({
    teaser,
    isCardTeaser = false,
    forceSingleRow,
    isTopTeaserKategorien,
    backgroundColor,
    title,
}) => (
    <NonFlexingContainer
        testID="mediathek-teaser-card-list"
        centerVertical
        centerHorizontal={isTopTeaserKategorien}
        position="relative"
    >
        {isTopTeaserKategorien && (
            <NonFlexingContainer
                height={517}
                display={['none', 'none', 'none', 'flex']}
                testID="top-kategorien-svg"
            >
                <img
                    src="/images/top-kategorien-background.svg"
                    alt="top-kategorien"
                    style={{ objectPosition: 'right', height: '100%', objectFit: 'cover' }}
                />
            </NonFlexingContainer>
        )}

        <NonFlexingContainer
            position={
                isTopTeaserKategorien
                    ? ['relative', 'relative', 'relative', 'absolute']
                    : 'relative'
            }
            horizontal
            className="gap-[12px] mtsm:gap-[24px]"
            flexWrap={forceSingleRow ? ['wrap', 'nowrap', 'nowrap'] : 'wrap'}
            testID="teasers-wrapper"
        >
            {teaser.map((teaserEntry, index) => (
                <SingleMediathekTeaser
                    index={index}
                    key={`${teaserEntry.title}-${teaserEntry.id}-${teaserEntry.componentKey}`}
                    teaser={teaserEntry}
                    backgroundColor={backgroundColor}
                    isCardTeaser={isCardTeaser}
                    title={title}
                />
            ))}
        </NonFlexingContainer>
    </NonFlexingContainer>
)

export default MediathekListTeaser
