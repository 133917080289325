import React, { FC, PropsWithChildren } from 'react'
import { ComponentType } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { PixelType, ResponsiveType, SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import TeaserCardHeadline from './Headline'

interface MediathekCardProps extends SystemSpacingsProps {
    backgroundColor?: 'pearl' | 'onyx' | 'TRANSPARENT'
    lazy?: boolean
    disableContentSpaceX?: boolean
    minHeight?: ResponsiveType<number | PixelType>
    title?: string
    titleHref?: string
    imageUrl?: string
    onPress?: () => void
    isTopTeaserKategorien?: boolean
    teaserCardType?: ComponentType
}

const MediathekCard: FC<MediathekCardProps & PropsWithChildren> = ({
    children,
    backgroundColor,
    lazy,
    minHeight,
    title,
    titleHref,
    imageUrl,
    isTopTeaserKategorien,
    teaserCardType,
    onPress,
}) => {
    return (
        <NonFlexingContainer
            backgroundColor={backgroundColor}
            minHeight={minHeight}
            testID="card-wrapper"
        >
            {title && (
                <TeaserCardHeadline
                    title={title}
                    titleHref={titleHref}
                    onPress={onPress}
                    lazy={lazy}
                    imageUrl={imageUrl}
                    isTopTeaserKategorien={isTopTeaserKategorien}
                    teaserCardType={teaserCardType}
                />
            )}
            {children}
        </NonFlexingContainer>
    )
}

export default MediathekCard
