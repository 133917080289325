import { ReactElement } from 'react'
import { useRouter } from 'next/router'
import { AdProps, MediathekTeaserProps } from '@sport1/types/web'
import { GetMediathekTeaserComponentProps } from '../types'
import MediathekTeaser from '@/components/MediathekTeaserCard/MediathekTeaser'

interface SingleMediathekTeaserProps extends GetMediathekTeaserComponentProps {
    teaser: AdProps | MediathekTeaserProps
    backgroundColor?: 'pearl' | 'onyx' | 'TRANSPARENT'
}

const SingleMediathekTeaser = ({
    index,
    key,
    isHorizontal,
    isCardTeaser,
    verticalExpand,
    headlineNumberOfLines,
    noExpand,
    teaser,
    backgroundColor,
    title,
}: SingleMediathekTeaserProps): ReactElement | null => {
    const { asPath } = useRouter()

    return (
        <MediathekTeaser
            key={`${teaser.componentKey}-${key}`}
            backgroundColor={backgroundColor}
            isCardTeaser={isCardTeaser}
            teaserType={teaser.type}
            isHorizontal={isHorizontal}
            teaser={teaser}
            expand={!noExpand}
            headlineNumberOfLines={headlineNumberOfLines}
            verticalExpand={verticalExpand}
            isFirstTeaserOnMobileView={index === 0}
            pathName={asPath}
            title={title}
        />
    )
}

export default SingleMediathekTeaser
