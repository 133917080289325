import {
    VideoTeaserProps,
    PortraitTeaserProps,
    PodcastMediathekTeaserProps,
    TeaserLabelType,
    IconTeaserProps,
} from '@sport1/types/web'
import Types from '@sport1/news-styleguide/Types'
import { getVideoSlug } from '@/helpers/slugHelper'
import { LabelTypesMap } from '@/components/Teaser'
import { LabelTextMap } from '@/components/MediathekHeroTeaserCard/MediathekTeaser'

export const getLabelProps = (
    rawLabel: TeaserLabelType | undefined
): [Types.TeaserLabelProps] | [Types.TeaserLabelProps, Types.TeaserLabelProps] | undefined => {
    const type = LabelTypesMap.get(rawLabel)
    const text = LabelTextMap.get(rawLabel)
    if (type) {
        if (type === 'onyx') {
            if (text) {
                return [{ labelType: type, labelText: text }]
            }
        } else {
            return [{ labelType: type }]
        }
    }
}

export const getVideoProps = (video: VideoTeaserProps) => {
    const { imageUrl, duration, teaserIcon } = video
    return {
        mainImageUrl: imageUrl || '',
        videoDuration: duration,
        href: getVideoSlug(video, true),
        teaserIcon,
    }
}

export const getPortraitProps = (teaser: PortraitTeaserProps) => {
    const { imageUrl, name, order, showNumber, url } = teaser
    const numberToShow = order !== undefined ? order + 1 : undefined
    return {
        mainImageUrl: imageUrl || '',
        teaserHeadline: name,
        href: url,
        number: showNumber
            ? (numberToShow as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | undefined)
            : undefined,
    }
}

export const getPodcastProps = (teaser: PodcastMediathekTeaserProps) => {
    const { coverImage, title, url } = teaser
    return {
        mainImageUrl: coverImage || '',
        teaserHeadline: title,
        href: url,
    }
}

export const getIconTeaserProps = (teaser: IconTeaserProps) => {
    const { url, iconUrl } = teaser
    return {
        href: url,
        iconUrl: iconUrl || '',
    }
}
