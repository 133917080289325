import React, { FC } from 'react'
import { ComponentType } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'

interface CardProps extends SystemSpacingsProps {
    title: string
    titleHref?: string
    lazy?: boolean
    imageUrl?: string
    onPress?: () => void
    isTopTeaserKategorien?: boolean
    teaserCardType?: ComponentType
}

const TeaserCardHeadline: FC<CardProps> = ({
    title,
    marginX,
    titleHref,
    onPress,
    isTopTeaserKategorien,
    teaserCardType,
}) => {
    return (
        <NonFlexingContainer
            marginX={marginX}
            paddingBottom={['spacing-6', 'spacing-9', 'spacing-9']}
            backgroundColor="transparent"
            testID="teaser-card-headline-wrapper"
        >
            <NonFlexingContainer>
                <CardHeadline
                    cardHeadlineType={
                        isTopTeaserKategorien || teaserCardType === ComponentType.HERO_TEASER_CARD
                            ? ['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineL']
                            : ['cardHeadlineXS', 'cardHeadlineS', 'cardHeadlineM']
                    }
                    text={title}
                    rightIconName={titleHref ? 'scroll-right' : undefined}
                    onPress={onPress}
                    href={titleHref}
                    testID="teaser-card-headline"
                />
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default TeaserCardHeadline
