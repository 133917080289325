import React, { FC, useCallback, useMemo, useRef } from 'react'
import dynamic from 'next/dynamic'
import { BasicMediathekTeaserProps, TeaserLabelType, VideoTeaserProps } from '@sport1/types/web'
import { useRouter } from 'next/router'
import { MediathekTeaserComponentProps } from '../types'
import { publishDateIsCurrentDate } from '@/helpers/dateHelper'
import { getVideoSlug } from '@/helpers/slugHelper'
import useInViewport from '@/hooks/useInViewport'
import { ComponentProps, MediathekTeaserTypes } from '@/types/teaser'
import { mapTrackingData, useTracking } from '@/utils/tracking/TrackingProvider'
import { takeLastTagWithKeys } from '@/helpers/tagsHelper'

const HeroTeaser = dynamic(() => import('@sport1/news-styleguide/HeroTeaser'))
const HeroOption = dynamic(() => import('@sport1/news-styleguide/HeroOption'))

export const LabelTypesMap: Map<
    TeaserLabelType | undefined,
    'live' | 'onyx' | 'breaking' | undefined
> = new Map([
    ['LIVE', 'live'],
    ['BREAKING_NEWS', 'breaking'],
    ['EXCLUSIVE', 'onyx'],
    ['UPDATE', 'onyx'],
    ['COLUMN', 'onyx'],
    ['COMMENT', 'onyx'],
    ['BRANDSTORY', 'onyx'],
    ['SPONSORED', 'onyx'],
])

export const LabelTextMap: Map<TeaserLabelType | undefined, string | undefined> = new Map([
    ['LIVE', 'live'],
    ['EXCLUSIVE', 'EXKLUSIV'],
    ['UPDATE', 'UPDATE'],
    ['COLUMN', 'KOLUMNE'],
    ['COMMENT', 'KOMMENTAR'],
    ['BRANDSTORY', 'BRAND STORY'],
    ['SPONSORED', 'SPONSORED'],
])

const MediathekHeroTeaser: FC<MediathekTeaserComponentProps> = ({
    teaser,
    optionsLength,
    activeOption,
    handleNext,
    handlePrevious,
    teaserType = MediathekTeaserTypes.VIDEO_TEASER,
}) => {
    const trackingData = mapTrackingData(teaser.content[0].meta?.tracking)
    const { trackTeaserImpression, trackTeaserClick } = useTracking()
    const router = useRouter()
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useInViewport(ref)

    React.useEffect(() => {
        if (isVisible) {
            trackTeaserImpression(trackingData)
        }
    }, [isVisible, trackingData, trackTeaserImpression])

    const getBasicProps = useCallback(
        (data: BasicMediathekTeaserProps): ComponentProps => {
            const { tags, publishedAt, title, imageUrl } = data
            const tag = takeLastTagWithKeys(tags, ['iconUrl'])
            return {
                tag: {
                    name: tag?.title || '',
                    imageUrl: tag?.iconUrl,
                    imageAlt: tag?.title,
                },
                mainImageAlt: title,
                mainImageUrl: imageUrl || '',
                published: publishedAt ? publishDateIsCurrentDate(publishedAt) : undefined,
                headlineText: title,
                type: 'VIDEO',
                onPress: () => trackTeaserClick(trackingData),
                labels: tag?.title
                    ? [{ labelType: 'competition', labelText: tag?.title }]
                    : undefined,
            }
        },
        [trackingData, trackTeaserClick]
    )

    const getVideoProps = useCallback(
        (teaser: VideoTeaserProps): ComponentProps => {
            const { imageUrl, duration, teaserIcon } = teaser
            return {
                ...getBasicProps(teaser),
                mainImageUrl: imageUrl || '',
                videoDuration: duration,
                href: getVideoSlug(teaser, true),
                teaserIcon,
                type: 'VIDEO',
            }
        },
        [getBasicProps]
    )

    const teaserProps = getVideoProps(teaser.content[0] as VideoTeaserProps)

    const handlePress = useCallback(() => {
        if (teaser?.content[0]?.slug) {
            router.push(teaser.content[0].slug)
        }
    }, [router, teaser.content])

    return useMemo(() => {
        switch (teaserType) {
            case MediathekTeaserTypes.HERO_TEASER:
                return (
                    <HeroTeaser
                        {...teaserProps}
                        size={['S', 'M', 'L', 'XL', 'XL']}
                        buttonText="ANSEHEN"
                        buttonIcon="play-active"
                        onPress={handlePress}
                        showButtons={optionsLength > 1}
                        onPressLeft={handlePrevious}
                        onPressRight={handleNext}
                    />
                )
            case MediathekTeaserTypes.HERO_OPTION:
                return (
                    <HeroOption
                        {...teaserProps}
                        size={['XS', 'S', 'M', 'L', 'XL']}
                        active={activeOption}
                    />
                )
        }
    }, [
        teaserType,
        teaserProps,
        optionsLength,
        handlePrevious,
        handleNext,
        activeOption,
        handlePress,
    ])
}

export default MediathekHeroTeaser
