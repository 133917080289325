import React, { FC, useEffect, useMemo, useRef } from 'react'
import dynamic from 'next/dynamic'
import { ComponentType, MediathekTeaserProps } from '@sport1/types/web'
import SquareTeaser from '@sport1/news-styleguide/SquareTeaser'
import Teaser from '@sport1/news-styleguide/Teaser'
import { ListTeaserProps } from '@sport1/news-styleguide/ListTeaser'
import { IconTeaserProps } from '@sport1/news-styleguide/IconTeaser'
import useGetComponentsProps from './useGetComponentProps'
import useInViewport from '@/hooks/useInViewport'
import { mapTrackingData, useTracking } from '@/utils/tracking/TrackingProvider'
import nofollowExternal from '@/utils/url/nofollowExternal'

const ListTeaser = dynamic(() => import('@sport1/news-styleguide/ListTeaser'))
const PortraitTeaser = dynamic(() => import('@sport1/news-styleguide/PortraitTeaser'))
const IconTeaser = dynamic(() => import('@sport1/news-styleguide/IconTeaser'))

type MediathekTeaserComponentProps = {
    backgroundColor?: 'pearl' | 'onyx' | 'TRANSPARENT'
    title?: string
    teaserType?: ComponentType
    teaser: MediathekTeaserProps | ListTeaserProps | IconTeaserProps
    expand?: boolean
    isCardTeaser?: boolean
    headlineNumberOfLines?: number
    verticalExpand?: boolean
    isHorizontal?: boolean
    isFirstTeaserOnMobileView?: boolean
    pathName?: string
}

const MediathekTeaser: FC<MediathekTeaserComponentProps> = ({
    backgroundColor,
    teaser,
    isHorizontal,
    isCardTeaser = false,
    isFirstTeaserOnMobileView,
    title,
}) => {
    const trackingData = mapTrackingData(teaser.meta?.tracking)
    const { trackTeaserImpression } = useTracking()
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useInViewport(ref)
    const webTarget = teaser.target ? teaser.target : '_self'

    useEffect(() => {
        if (isVisible) {
            trackTeaserImpression(trackingData)
        }
    }, [isVisible, trackingData, trackTeaserImpression])

    const teaserProps = useGetComponentsProps(teaser, trackingData, backgroundColor)
    return useMemo(() => {
        const webTBRegionItem = isCardTeaser ? '' : undefined

        switch (teaser.type) {
            case ComponentType.VIDEO_TEASER:
                if (isHorizontal) {
                    return (
                        <>
                            <div className="grid mtsm:hidden" data-testid="video-teaser-horizontal">
                                <Teaser
                                    testID={`${teaser.type}VerticalTeaserXS${teaserProps.headlineText}`}
                                    variant="VerticalTeaserXS"
                                    {...teaserProps}
                                    innerRef={ref}
                                    webRel={nofollowExternal}
                                    webTarget={webTarget}
                                    noExpand
                                />
                            </div>
                            <div className="hidden mtsm:grid mtmd:hidden">
                                <Teaser
                                    testID={`${teaser.type}VerticalTeaserXS${teaserProps.headlineText}`}
                                    variant="VerticalTeaserXS"
                                    {...teaserProps}
                                    innerRef={ref}
                                    webRel={nofollowExternal}
                                    webTarget={webTarget}
                                    noExpand
                                />
                            </div>
                            <div className="hidden mtmd:grid">
                                <Teaser
                                    testID={`${teaser.type}VerticalTeaserL${teaserProps.headlineText}`}
                                    variant="VerticalTeaserM"
                                    {...teaserProps}
                                    innerRef={ref}
                                    webRel={nofollowExternal}
                                    webTarget={webTarget}
                                    noExpand
                                />
                            </div>
                        </>
                    )
                }
                return (
                    <>
                        <div className="grid mtsm:hidden w-full" data-testid="video-teaser">
                            <Teaser
                                testID={`${teaser.type}HorizontalTeaserM${teaserProps.headlineText}`}
                                variant={
                                    isFirstTeaserOnMobileView && title !== undefined
                                        ? 'VerticalTeaserM'
                                        : 'HorizontalTeaserM'
                                }
                                {...teaserProps}
                                innerRef={ref}
                                webRel={nofollowExternal}
                                webTarget={webTarget}
                            />
                        </div>
                        <div className="hidden mtsm:grid mtmd:hidden">
                            <Teaser
                                testID={`${teaser.type}VerticalTeaserXS${teaserProps.headlineText}`}
                                variant="VerticalTeaserXS"
                                {...teaserProps}
                                innerRef={ref}
                                webRel={nofollowExternal}
                                webTarget={webTarget}
                                noExpand
                            />
                        </div>
                        <div className="hidden mtmd:grid">
                            <Teaser
                                testID={`${teaser.type}VerticalTeaserL${teaserProps.headlineText}`}
                                variant="VerticalTeaserM"
                                {...teaserProps}
                                innerRef={ref}
                                webRel={nofollowExternal}
                                webTarget={webTarget}
                                noExpand
                            />
                        </div>
                    </>
                )
            case ComponentType.LIST:
                return (
                    <ListTeaser
                        testID={`LIST_${teaserProps.headlineText}`}
                        webTBRegionItem={webTBRegionItem}
                        webTBThumbnail={isCardTeaser}
                        {...teaserProps}
                        innerRef={ref}
                        webTarget={webTarget}
                        webRel={nofollowExternal}
                    />
                )
            case ComponentType.ICON_TEASER:
                return (
                    <IconTeaser
                        testID={`ICON_TEASER_${teaserProps.headlineText}`}
                        iconUrl=""
                        {...teaserProps}
                    />
                )
            case ComponentType.PORTRAIT_TEASER:
                return (
                    <div style={{ backgroundColor: '#000', display: 'flex' }}>
                        <PortraitTeaser
                            testID={`PORTRAIT_TEASER_${teaserProps.headlineText}`}
                            webTarget={webTarget}
                            size={['S', 'M', 'L', 'L']}
                            {...teaserProps}
                        />
                    </div>
                )
            case ComponentType.PODCAST_TEASER:
                return (
                    <SquareTeaser
                        testID={`PODCAST_TEASER_${teaserProps.headlineText}`}
                        size={['S', 'M', 'L']}
                        {...teaserProps}
                        webTarget={webTarget}
                        innerRef={ref}
                    />
                )
        }
    }, [
        isCardTeaser,
        teaserProps,
        teaser,
        webTarget,
        isHorizontal,
        isFirstTeaserOnMobileView,
        title,
    ])
}

export default MediathekTeaser
